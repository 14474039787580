import { useStaticQuery, graphql } from 'gatsby'

export const useCategoryPosts = category => {
  const { categoryPosts } = useStaticQuery(categoryPostsQuery)
  return categoryPosts.nodes || null
}

const categoryPostsQuery = graphql`
  query allCategoryArticleQuery(
    $includeExcerpt: Boolean! = true
    $includeTimeToRead: Boolean! = true
    $imageQuality: Int! = 75
  ) {
    categoryPosts: allArticle(
      filter: { private: { ne: true }, draft: { ne: true } }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
    }
  }
`
