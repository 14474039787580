import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentContainer from '@solid-ui-components/ContentContainer'
import ContentButtons from '@solid-ui-components/ContentButtons'
import { Script } from "gatsby"
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import { Button, Link } from 'theme-ui'

const Calendly = ({
  ...props
}) => {
  
  const openCalendly = () => {
    
    window.Calendly.initPopupWidget({url: 'https://calendly.com/alexandru-ops-ai/30min'});

    return false;
  }

  const text = [
    {
      "text": "Transform Your Development Process with Toggly",
      "color": "alpha",
      "variant": "h5"
    },
    {
      "text": "Unleash the Power of Feature Toggles: Develop, Test, and Release Seamlessly."
    }
  ];

  const buttons = [
    {
      "text": "Download our Free Cheat Sheet",
      "type": "PAGE",
      "link": "",
      "variant": "primary-light"
    }
  ];

  return (
    <>
    
    <Container>
      <ContentContainer variant='cards.paper'>
        <Flex
          sx={{
            flexDirection: [`column`, `row`],
            justifyContent: `space-between`,
            alignItems: `center`,
            textAlign: [`center`, `left`],
            gap: `15px`
          }}
        >
          <Box>
            <ContentText content={text} />
          </Box>
          <Button onClick={openCalendly} variant={'primary'}>Get a Demo</Button>
        </Flex>
      </ContentContainer>
    </Container>

    </>
  );
}

export default Calendly
