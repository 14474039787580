import React from 'react'
import { Container } from 'theme-ui'
import ContentButtons from '@solid-ui-components/ContentButtons'
import './TrustNav.css'

const TrustNav = ({ content: { buttons } }) => {
  return (
    <div className='trust-nav-wrapper'>
      <Container sx={{ textAlign: `center`, px: 0 }}>
        {buttons && (
          <ContentButtons content={buttons} variant={'trustNavigation'} />
        )}
      </Container>
    </div>
  )
}

export default TrustNav
